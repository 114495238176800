import * as React from "react"
import PropTypes from "prop-types"
import { Disclosure } from '@headlessui/react'
import { PhoneIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import LogoSvg from "../images/logo.svg"

const navigation = [
  { name: "Descripción general", href: "/", current: false },
  { name: "Funcionamiento", href: "/how-it-works/", current: false },
  { name: "Costo", href: "/pricing/", current: false },
  { name: "Preguntas frecuentes", href: "/faq/", current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const url_pathname = typeof window !== 'undefined' ? window.location.pathname : '';

const Header = ({ siteTitle }) => (
  <header>
    <Disclosure as="nav" className="w-screen fixed bg-primary z-10">
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto lg:px-6 lg:px-16">
            <div className="relative flex items-center justify-between h-14 fixed">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden ">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset ">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-8 w-8 -ml-0.5" aria-hidden="true" color="#BE3A34" />
                  ) : (
                    <MenuIcon className="block h-8 w-8 -ml-0.5" aria-hidden="true" color="#BE3A34" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex-shrink-0 flex items-center">
                <a
            className="md:block"
            href="/"
            rel="noopener noreferrer"
          >
                  <img
                    className="block lg:hidden h-8 w-auto"
                    alt="logo sumtech.tech"
                    src={LogoSvg}
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    alt="logo sumtech.tech"
                    src={LogoSvg}
                  />
                  </a>
                </div>
                <div className="hidden lg:block lg:ml-6">
                  <div className="flex">
                    {navigation.map(item => {
                item.current = url_pathname === item.href;
                return(
                      <a
                        id={item.name}
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "text-red-accent font-bold"
                            : "text-gray  hover:text-red-accent",
                          "px-3 py-2 rounded-lg text-lg font-body"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    )})}
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="inline-flex text-white py-4">
                  <a href="tel:528183334055" className="inline-flex font-body text-gray px-6 h-6">
                    <PhoneIcon className="block w-6" aria-hidden="true" />
                    +52 81 8333 4055
                  </a>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="inline-flex shadow text-white h-10">
                  <a href="https://calendar.google.com/calendar/selfsched?sstoken=UU5PQWlwRjBDRC1ifGRlZmF1bHR8N2EzNmM3NmQyZTlmMWRiMzMwYmMwNGViNDg4YzBlMGM"
                    target="_blank" className="inline-flex items-center justify-center px-5 font-lg text-white bg-green">
                    Solicitar una demostración
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className=" lg:hidden ">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map(item => {
                item.current = url_pathname === item.href;
                return(
                  <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-red text-white"
                      : "text-gray hover:bg-red hover:text-white",
                    "block px-3 py-2 text-base font-body"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </a>
                )
                
              })}
              <div className="border-t-2 pt-2 pl-2 border-red lg:hidden">
                <div className="inline-flex text-white py-4">
                  <a href="tel:528183334055" className="inline-flex font-body text-red h-6">
                    <PhoneIcon className="block w-6 mr-2" aria-hidden="true" />
                    +52 81 8333 4055
                  </a>
                </div>
              </div>
              <div className="px-2 lg:hidden">
                <div className="inline-flex shadow text-white h-10">
                  <a href="https://calendar.google.com/calendar/selfsched?sstoken=UU5PQWlwRjBDRC1ifGRlZmF1bHR8N2EzNmM3NmQyZTlmMWRiMzMwYmMwNGViNDg4YzBlMGM"
                    target="_blank"
                    className="inline-flex items-center justify-center px-5 font-lg text-white bg-green">
                    Solicitar una demostración
                  </a>
                </div>
              </div>
            </div>

          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
