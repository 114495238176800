import React from "react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import facebook from "../images/icon_facebook.svg"
import twitter from "../images/icon_twitter.svg"
import linkedin from "../images/icon_linkedin.svg"
import youtube from "../images/icon_youtube.svg"
import instagram from "../images/icon_instagram.svg"
import LogoSvg from "../images/logo.svg"

const toggleFooterSection = (e: any) => {
  try {
    const list = e.target.parentElement.parentElement.querySelector("article")
    if (list.classList.contains("h-0")) {
      list.classList.remove("h-0")
    } else {
      list.classList.add("h-0")
    }
  } catch (error) {

  }
}

const Footer: React.FC = () => {
  return (
    <footer className="mt-8  font-body text-gray ">
      <div className="bg-primary">
        <div className="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto  px-4 md:px-8 lg:px-0 flex justify-between">
          <a
            className="hidden md:block"
            href="https://www.sumtech.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="block h-8 my-3"
              alt="logo sumtech.tech"
              src={LogoSvg}
            />
          </a>
          <div className="flex items-center my-4 md:my-0">
            Síguenos
            <a
              href="https://www.facebook.com/sumtech.tech"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className="block h-6 mx-1.5"
                alt="sumtech en facebook"
                src={facebook}
              />
            </a>
            <a
              href="https://www.twitter.com/sumtech_tech"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className="block h-6"
                alt="sumtech en twitter"
                src={twitter}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/sumtech-tech"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className="block h-6 mx-1.5"
                alt="sumtech en linkedin"
                src={linkedin}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCrq49xn6J9j5JOjjB6iyl-w/videos"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className="block h-6"
                alt="sumtech en youtube"
                src={youtube}
              />
            </a>
            <a
              href="https://instagram.com/sumtech_tech"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className="block h-6 ml-1.5"
                alt="sumtech en instagram"
                src={instagram}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="md:pt-4 md:pb-4 bg-secondary">
        <div className="flex flex-wrap max-w-screen-xl 2xl:max-w-screen-2xl mx-auto  px-0 md:px-8 lg:px-0 mx-auto">
          <section className="relative border-b px-4 pb-4 md:py-3 w-full md:border-none md:w-1/4">
            <div className="md:hidden flex justify-between" onClick={toggleFooterSection}>
              <h4 className="py-4">
                Acerca de Evofinder
              </h4>
              <ChevronDownIcon
                className="block h-14 w-8"
                aria-hidden="true"
                color="#425563"
                
              />
              
            </div>
            <h4 className="hidden md:block">Acerca de Evofinder</h4>
            <article className="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden text-sm">
              <ul className="my-5">
                <li className="my-2">
                  <a href="/">Descripción general</a>
                </li>
                <li className="my-2">
                  <a href="/how-it-works">Funcionamiento</a>
                </li>
                <li className="my-2">
                  <a href="/pricing">Costo</a>
                </li>
                <li className="my-2">
                  <a href="/faq">Preguntas frecuentes</a>
                </li>
              </ul>
            </article>
          </section>
          <section className="relative border-b px-4 pb-4 md:py-3 w-full md:border-none md:w-1/4">
            <div className="md:hidden flex justify-between" onClick={toggleFooterSection}>
              <h4 className="py-4">
                Aprendizaje y asistencia</h4>
              <ChevronDownIcon
                className="block h-14 w-8"
                aria-hidden="true"
                color="#425563"
              />
            </div>
            <h4 className="hidden md:block">Aprendizaje y asistencia</h4>
            <article className="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden text-sm">
              <ul className="my-5">
                <li className="my-2">
                  <a href="tel:528183334055">Contactar con ventas</a>
                </li>
                <li className="my-2">
                  <a href="mailto:info@sumtech.tech">Cursos de balística</a>
                </li>
                <li className="my-2">
                  <a href="tel:528183334055">Servicios posventa</a>
                </li>
              </ul>
            </article>
          </section>
          <section className="relative border-b px-4 pb-4 md:py-3 w-full md:border-none md:w-1/4">
            <div className="md:hidden flex justify-between" onClick={toggleFooterSection}>
              <h4 className="py-4">
                Más soluciones
              </h4>
              <ChevronDownIcon
                className="block h-14 w-8"
                aria-hidden="true"
                color="#425563"
              />
            </div>
            <h4 className="hidden md:block">Más soluciones</h4>
            <article className="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden text-sm">
              <ul className="my-5">
                <li className="my-2">
                  <a href="https://www.sumtech.tech/">Página principal</a>
                </li>
                <li className="my-2">
                  <a href="https://www.sumtech.tech/forense/">Productos para forense</a>
                </li>
                <li className="my-2">
                  <a href="https://www.sumtech.tech/forense/balistica/microscopios">Microscopios de comparación</a>
                </li>
              </ul>
            </article>
          </section>
          <section className="relative border-b px-4 pb-4 md:py-3 w-full md:border-none md:w-1/4">
            <div className="md:hidden flex justify-between" onClick={toggleFooterSection}>
              <h4 className="py-4" >
                Información de contacto
              </h4>
              <ChevronDownIcon
                className="block h-14 w-8"
                aria-hidden="true"
                color="#425563"
              />
            </div>
            <h4 className="hidden md:block">Información de contacto</h4>
            <article className="h-0 md:h-auto -mt-4 md:mt-0 overflow-hidden text-sm">
              <ul className="my-5">
                <li className="my-2">
                  <p>Washington 2525, Deportivo Obispado.</p>
                </li>
                <li className="my-2">
                  <p>Monterrey, NL, C.P. 64040</p>
                </li>
                <li className="my-2">
                  <a href="tel:528183334055">Teléfono: +52 (81) 8333 4055</a>
                </li>
                <li className="my-2">
                  <a href="mailto:info@sumtech.tech">info@sumtech.tech</a>
                </li>
              </ul>
            </article>
          </section>
        </div>
      </div>
      <div className="border-t md:px-2 bg-primary">
        <section className="flex flex-col md:flex-row md:max-w-xl md:justify-between md:border-solid md:border-t text-gray-700 font-light text-sm pt-4 pb-6 md:pt-5 md:pb-6 w-full">
          <p className="leading-8 tracking-wide">
            &copy;Copyright {(new Date().getFullYear())}. All Rights Reserved
          </p>
          <a href="https://www.sumtech.tech/aviso-de-privacidad" target="_blank">
            <p className="leading-8 tracking-wide">Aviso de privacidad</p>
          </a>
          <a href="https://www.sumtech.tech/" target="_blank">
            <p className="leading-8 tracking-wide">Acerca de SUMTECH</p>
          </a>
        </section>
      </div>
    </footer>
  )
}

export default Footer
